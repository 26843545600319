<template>
    <div v-if="show" class="mt-3">
        <v-row no-gutters>
            <v-col sm="12" md="6">
                <div class="text-h6 font-weight-bold primary--text my-md-4 my-sm-2">Loan History</div>
            </v-col>

            <v-col sm="12" md="6">
                <div class="float-right">
                    <v-tabs v-model="tabs" class="tabs-container mb-3">
                        <v-tab v-for="item in [{name: 'All', value: null}, {name: 'Approved', value: 1}, {name: 'Rejected', value: 2}, {name: 'Pending', value: 0}]" :key="item.name" @click="filter=item.value">
                            <div class="text-uppercase">{{item.name}}</div>
                        </v-tab>
                        <v-tabs-slider color="primary"></v-tabs-slider>
                    </v-tabs>
                </div>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="4">
                <v-text-field
                        v-model="search"
                        label="search"
                        clearable
                        placeholder="search by name, status or account"
                        @input="isTyping = true"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        @click:clear="search = ''"
                        dense
                />
            </v-col>
            <v-col cols="3">
                <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                :value="startTimeFormat"
                                clearable
                                label="Start"
                                readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="start_time = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="start_time"
                            @change="menu1 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                :value="endTimeFormat"
                                clearable
                                label="End"
                                readonly
                                outlined
                                dense
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="end_time = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="end_time"
                            @change="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="2">
                <div class="float-right">
                    <v-btn
                            color="secondary accent-4"
                            class="px-md-5 py-4"
                            @click.prevent="exportXLSX"
                            tile
                    >
                        <v-icon class="mr-2">mdi-download</v-icon>
                        Download
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-data-table
                :headers="headers"
                :items="loans"
                disable-pagination
                :hide-default-footer="true"
        >
            <template v-slot:[`item.index`]="{ item }">
                {{ loans.indexOf(item) + 1 }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div>{{item.amount | floatNairaFormat}}</div>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
                <div>{{item.balance | floatNairaFormat}}</div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | dashboardDateFormat }}</div>
            </template>
            <template v-slot:[`item.expiry_date`]="{ item }">
                <div>{{ item.expiry_date | dashboardDateFormat }}</div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div :class="item.balance>0? 'lime--text' : 'primary--text'">{{ item.balance>0? 'Pending' : 'Paid'}}</div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center" align="center">
                    <v-chip
                            v-if="!item.is_approved"
                            @click="cancelLoan(item.id)"
                            class="ma-2"
                            color="error"
                            label
                            text-color="white"
                    >
                        <v-icon left>mdi-delete-forever</v-icon>
                        Delete
                    </v-chip>

                    <v-chip
                            v-else-if="item.balance>0&&item.is_approved === 1"
                            @click.prevent="repayLoan(item.id)"
                            class="ma-2"
                            color="primary"
                            label
                            text-color="white"
                    >
                        <v-icon left>mdi-currency-ngn</v-icon>
                        Repay
                    </v-chip>

                    <v-chip
                            v-else-if="item.balance>0&&item.is_approved === 2"
                            class="ma-2"
                            label
                            color="warning"
                    >
                        <v-icon left>mdi-alert-remove-outline</v-icon>
                        Rejected
                    </v-chip>

                    <v-chip
                            v-else
                            class="ma-2"
                            label
                            :disabled="true"
                    >
                        <v-icon left>mdi-check-circle</v-icon>
                        Repaid
                    </v-chip>

                </v-row>
            </template>
        </v-data-table>
        <div class="float-right mt-3" v-if="loans.length">
            <v-pagination
                    v-model="page"
                    :length="getCount()"
                    total-visible="7"
                    @input="getLoans"
            ></v-pagination>
        </div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" color="primary" width="8"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import XLSX from "xlsx";
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ['show'],
        data: () => ({
            search: '',
            filter: '',
            tabs: null,
            menu1: false,
            menu2: false,
            start_time: null,
            end_time: null,
            isTyping: false,
            headers: [
                {text: 'S/No', value: 'index', align: 'start', sortable: false},
                {text: 'Amount Granted', value: 'amount'},
                {text: 'Date Applied', value: 'created_at'},
                {text: 'Date Due', value: 'expiry_date'},
                {text: 'Unpaid Balance', value: 'balance'},
                {text: 'Status', value: 'status'},
                {text: 'Action', value: 'action'},
            ],
            page: 1,
            showRepay: false,
            showDebitPay: false,
            showCardModal: false,
            showPin: false,
            loanId: '',
            amount: 0.00,
            loading: true,
        }),
        methods: {
            ...mapActions('loan', ['userLoans']),
            defaultStates() {
                this.page = 1;
                this.start_time = null;
                this.end_time = null;
            },
            getLoans() {
                const data = {
                    owner_id: this.$route.params.id,
                    filter: this.filter !== null? this.filter : "",
                    search: this.search !== null? this.search : "",
                    end: this.end_time !== null? this.end_time : "",
                    start: this.start_time !== null? this.start_time : "",
                    page: this.page
                };
                this.userLoans(data);
                this.loading = false;
            },
            getCount() {
                return Math.ceil(this.loanCount / 10);
            },
            exportXLSX() {
                const data = XLSX.utils.json_to_sheet(this.getExcelData());
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, 'data');
                const date = new Date();
                const filename =
                    "report_" +
                    date.getFullYear() +
                    ("0" + (date.getMonth() + 1)).slice(-2) +
                    ("0" + date.getDate()).slice(-2) +
                    ("0" + date.getHours()).slice(-2) +
                    ("0" + date.getMinutes()).slice(-2) +
                    ("0" + date.getSeconds()).slice(-2) +
                    ".xlsx";
                XLSX.writeFile(wb, filename);
            },
            getExcelData() {
                let data = [];
                this.loans.map(item => {
                    let obj = {};
                    obj.Amount = item.amount;
                    obj.Balance = item.balance;
                    obj.Originating_Fee = item.originating_fee;
                    obj.Interest = item.interest;
                    obj.Status = item.is_approved? 'Approved' : 'Declined';
                    obj.Expiry_Date = moment(String(item.expiry_date)).format("DD/MM/YYYY hh:mma");
                    obj.Date_Created = moment(String(item.created_at)).format("DD/MM/YYYY hh:mma");


                    data.push(obj);
                });
                return data;
            },
            repayLoan(id) {
                this.loanId = id;
                this.showRepay = true;
            },
            cancelLoan(id) {
                this.deleteLoan(id).then(res => {
                    this.getLoans();
                    this.$toast.success('unprocessed loan deleted successfully');
                }).catch(err => {
                    this.$toast.error('error in deleting loan');
                })
            },
        },
        computed: {
            ...mapGetters('loan', ['loans', 'loanCount']),
            startTimeFormat() {
                return this.start_time ? moment(this.start_time).format('Do MMM [,] YYYY') : ''
            },
            endTimeFormat() {
                return this.end_time ? moment(this.end_time).format('Do MMM [,] YYYY') : ''
            },
        },
        mounted() {
            this.getLoans();
        }
    }
</script>

<style scoped>

</style>