<template>
    <v-container class="px-5">
        <div class="px-5">
            <div class="grey--text mb-4">Hey!
                <span class="text-h5 font-weight-bold primary--text ml-3" v-text="business.name"></span>
            </div>
            <v-row no-gutters class="grey lighten-5 pa-3 pa-md-8 rounded-lg">
                <v-col cols="8" class="pa-3">
                    <div class="text-h5 lime--text darken-1 mb-5">Cash Loan</div>
                    <div class="my-2">Get a loan of up to {{maxLoanAmount | floatNairaFormat}} for your<br/> business need</div>
                    <v-row no-gutters>
                        <v-col cols="7">
                            <div class="font-weight-bold text-h6 primary--text">
                                Total Loan Granted {{ loanBalance | floatNairaFormat}}
                            </div>
                        </v-col>
                        <v-col cols="5">
                            <v-row>
                                <v-col cols="6">
                                    <v-btn :disabled="pages.table!==true" tile color="primary darken-3" class="white--text py-5 px-2" @click.prevent="onApplyClick">
                                        Apply Now
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn :disabled="pages.table!==true" tile color="green darken-3" class="white--text py-5 px-2" @click.prevent="onApplyClick">
                                        Requests
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" class="pa-2">
                    <img src="@/static/assets/loan-icon.png" height="150px" width="auto" alt=""/>
                </v-col>
            </v-row>
            <loan-calculator-modal :show="modals.calculator" @onDismiss="modals.calculator=false"/>
            <repay-modal
                    @state="(state) => {modals.repay = state}"
                    :value="modals.repay"
                    @onSubmit="modals.repay = false"
            />
            <terms-and-conditions :show="modals.accept" :business-id="business_id" @dismiss="modals.accept=false" @onDisagree="showPage('table')"/>

            <!-- pages -->
            <update-business-kyc :show="pages.account"/>
            <loan-table :show="pages.table"/>

            <v-overlay :value="loading">
                <div class="mb-3 primary--text">..details loading</div>
                <v-progress-circular indeterminate size="64" color="primary" width="8"></v-progress-circular>
            </v-overlay>
        </div>
    </v-container>
</template>

<script>

    import ConfirmationModal from "../../loan/components/ConfirmationModal";
    import RepayModal from "../../loan/components/RepayModal";
    import LoanCalculator from "../../loan/components/LoanCalculator";
    import UpdateBusinessKyc from "./components/pages/UpdateBusinessKyc";
    import LoanSummary from "../../loan/components/LoanSummary";
    import LoanTable from "./components/pages/LoanTable";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import TermsAndConditions from "./components/modals/TermsAndConditions";
    import LoanCalculatorModal from "./components/modals/LoanCalculatorModal";

    export default {
        name: "index",
        components: {
            UpdateBusinessKyc,
            LoanCalculatorModal,
            TermsAndConditions,
            LoanTable,
            LoanSummary, LoanCalculator, RepayModal, ConfirmationModal},
        data: () => ({
            loading: false,
            maxLoanAmount: 50000,
            loanDetails: {},
            business_id: "",
            modals: {
                accept: false,
                calculator: false,
                confirm: false,
                update: false,
                repay: false,
                request: false,
            },
            pages: {
                table: false,
                calculator: false,
                account: false,
                summary: false,
                card: false,
                update: false,
            }
        }),
        methods: {
            ...mapActions('business', ['showBusiness', 'allGuarantors', 'createGuarantor', 'createBusinessAccount', 'showBusinessAccount']),
            ...mapActions('loan', ['allUserPaymentCards']),
            ...mapMutations('business', ['storeBusinessAccount']),
            initialise() {
                for (const key in this.modals) {
                    if (this.modals.hasOwnProperty(key)) {
                        this.modals[key] = false;
                    }
                }
                for (const key in this.pages) {
                    if (this.pages.hasOwnProperty(key)) {
                        this.pages[key] = false;
                    }
                }
            },
            fetchLoanDetails(data) {
                this.loanDetails = data;
            },
            showPage(page) {
                this.initialise();
                this.pages[page] = true;
            },
            showModal(modal) {
                for (const key in this.modals) {
                    if (this.modals.hasOwnProperty(key)) {
                        this.modals[key] = false;
                    }
                }
                this.modals[modal] = true;
            },
            getOrCreateBusinessAccount(){
                this.createBusinessAccount({business_id: this.$route.params.id}).then(res => {
                    this.storeBusinessAccount(res.data);
                    if (!res.data.is_onboarded) {
                        this.modals.accept = true;
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                })
            },
            onApplyClick() {
                this.loading = true;
                this.showBusinessAccount(this.$route.params.id).then(res => {
                    this.storeBusinessAccount(res.data);
                    if (!res.data.is_onboarded) {
                        this.modals.accept = true;
                    }else {
                        let isCompleted = res.data.is_completed;
                        let guarantors = res.data.guarantors;
                        if (isCompleted && guarantors.length >= 2) {
                            this.showPage('calculator');
                        }else {
                           this.showPage('account');
                        }
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.loading = false;
                });
            },
            onRequest() {
                this.loading = true;
                this.showBusinessAccount(this.$route.params.id).then(res => {
                    this.storeBusinessAccount(res.data);
                    if (res.data.is_completed && res.data.guarantors.length >= 2) {
                        this.showPage('account');
                    }else {
                        this.$router.push({name: 'Request'});
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(()=> {
                    this.loading = false;
                });
            },
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('loan', ['loanBalance']),
            ...mapGetters('business', ['business', 'guarantors', 'businessAccount']),
        },
        mounted() {
            this.business_id = this.$route.params.id;
            this.showPage('table');
            this.getOrCreateBusinessAccount();
            this.allUserPaymentCards(this.user.id);
            this.modals.accept = true;
            this.showBusiness(this.$route.params.id);
            this.allGuarantors(this.$route.params.id);
        }
    }
</script>

<style scoped>
    .h4 {
        font-size: xx-large;
    }
</style>
