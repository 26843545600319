<template>
    <v-row justify="center">
        <v-dialog
                v-model="show"
                persistent
                width="600px"
        >
            <v-card>
                <v-app-bar
                        height="60"
                        color="transparent"
                        class="pa-0 pt-md-1"
                        flat
                >
                    <v-spacer/>
                    <v-app-bar-title class="modal-title">
                        <span class="secondary--text">Tran</span>
                        <span class="primary--text mr-1">Save</span>
                        User & Agent Loan Policy
                    </v-app-bar-title>
                    <v-spacer/>
                    <v-btn icon @click="$emit('onDisagree')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <v-divider/>

                <v-card-text>
                    <div class="font-weight-bold mb-4 mt-3">
                        Please carefully read and understand these terms and conditions (T&C’s) for the TranSave loan:
                    </div>
                    <div class="font-weight-bold mb-4">
                        Why Choose TranSave Loans?
                    </div>
                    <ul class="mb-4">
                        <li>
                            Limit range from NGN5, 000 to NGN50, 000.
                        </li>
                        <li>
                            Flexible repayment between 10 days to 30 days.
                        </li>
                        <li>
                            No paperwork, No collateral.
                        </li>
                        <li>
                            Fast, Secure and Reliable.
                        </li>
                        <li>
                            No hidden fees or rollover charge
                        </li>
                    </ul>
                    <div class="font-weight-bold mb-4">
                        Eligibility
                    </div>
                    <div>
                        To be eligible for the TranSave Loan
                    </div>
                    <ul class="mb-4">
                        <li>
                            You must be a registered and verified user of TranSave
                        </li>
                        <li>
                            You must be above the age of 18 years
                        </li>
                        <li>
                            You must have a verifiable source of income (i.e an employer, employee or  business owner)
                        </li>
                        <li>
                            You must have a verifiable address
                        </li>
                        <li>
                            You must have an account with any commercial bank in Nigeria
                        </li>
                        <li>
                            You must provide credible guarantors  with a recognised means of identification (preferably National ID card)
                        </li>
                        <li>
                            TranSave will verify all guarantors to confirm credibility of the details provided.
                        </li>
                        <li>
                            Our guarantors’ verification process varies depending on the amount requested
                        </li>
                    </ul>

                    <div class="font-weight-bold mb-4">
                        Loan Request Format
                    </div>
                    <div>
                        The TranSave loan request is in two easy formats
                    </div>
                    <ul class="mb-4">
                        <li>
                            <span class="font-italic font-weight-bold">Direct request</span>: This implies requesting directly from your user dashboard by clicking on apply for loan button
                        </li>
                        <li>
                            <span class="font-italic font-weight-bold">Request through admin</span>: For situations whereby the user’s credit limit is lower that the intended loan amount, the user can request through admin by clicking on the “Apply Via Admin”, which enables the user to input the desired loan amount.  In this case, the admin decides if the user is eligible for the loan or not.
                        </li>
                        <li>
                            Users who apply through admin can request for loan above the stipulated 50,000 naira maximum limit for TranSave loan. However, the duration is still between 10 to 30 days.
                        </li>
                    </ul>

                    <div class="font-weight-bold mb-4">
                        Interest and Percentage
                    </div>
                    <ul class="mb-4">
                        <li>
                            A total percentage of 0.60% is charged per day on all TranSave loans. 0.5% of this is the interest rate while 0.1% is the originating fee.
                        </li>
                    </ul>
                    <div class="font-weight-bold mb-4">
                        Loan Limit
                    </div>
                    <ul class="mb-4">
                        <li>
                            Eligible users can borrow a minimum of five thousand naira (5000) and a maximum of fifty thousand naira (50000).
                        </li>
                        <li>
                            A user can only apply for another loan when the outstanding loan has been repaid.
                        </li>
                    </ul>
                    <div class="font-weight-bold mb-4">
                        Payment Schedule
                    </div>
                    <ul class="mb-4">
                        <li>
                            It takes a minimum of 10days and a maximum of 30 days to repay the Transave loan.
                        </li>
                        <li>
                            Users can set the time limit for the repayment of loan within the duration of 10 to 30 days.
                        </li>
                        <li>
                            Users can repay outstanding debt before the repayment date is due, these increases the user’s chances of getting more loans.
                        </li>
                    </ul>
                    <div class="font-weight-bold mb-4">
                        Default on Payment
                    </div>
                    <ul class="mb-4">
                        <li>
                            A user who fails to repay on the due date is liable to pay a 1% interest for each additional day of default.
                        </li>
                        <li>
                            Transave reserves the right to contact registered guarantors within 2 to 3 days of payment default.
                        </li>
                    </ul>
                    <div class="font-weight-bold">
                        By clicking on 'agree', you agree to the terms and conditions of the loan.
                    </div>
                </v-card-text>
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                            color="secondary darken-1"
                            tile
                            outlined
                            class="pa-6"
                            @click="onReject"
                    >
                        Disagree
                    </v-btn>
                    <v-btn
                            color="primary darken-1"
                            class="py-6 px-10"
                            tile
                            @click="onAccept"
                    >
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        props: {
            show: {
                required: true,
                type: Boolean,
                default: false,
            },
            businessId: {
                required: true,
                type: String,
            }
        },
        methods: {
            ...mapActions('business', ['updateBusinessAccount']),
            onAccept() {
                this.updateBusinessAccount(this.businessId, {is_onboarded: 1}).then(res => {
                    if(res.success) {
                        this.$emit('dismiss');
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                });
            },
            onReject() {
                this.updateBusinessAccount(this.businessId, {is_onboarded: 0}).then(res => {
                    if(res.success) {
                        this.$emit('onDisagree');
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .subheader {
        font-weight: bold;
        margin-bottom: 20px;
    }
</style>