<template>
    <div v-if="show" class="mt-5">
        <div class="primary--text font-weight-bold text-h5 pt-4">Business KYC Update</div>
        <v-stepper v-model="step" alt-labels flat tile min-height="580">
            <v-stepper-header>
                <v-stepper-step step="1">
                    Basic Information
                </v-stepper-step>

                <v-stepper-step step="2">
                    File Uploads Information
                </v-stepper-step>

                <v-stepper-step step="2">
                    Guarantors Information
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-form lazy-validation ref="form">
                    <v-stepper-content step="1">
                        <v-row class="mt-4">
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'country name is required']"
                                        v-model="form.country"
                                        outlined
                                        label="Country Name"
                                        placeholder="enter country name"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'state name is required']"
                                        v-model="form.state"
                                        outlined
                                        label="State Name"
                                        placeholder="enter state name"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'LGA name is required']"
                                        v-model="form.lga"
                                        outlined
                                        label="LGA Name"
                                        placeholder="enter LGA name"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'capitalisation is required']"
                                        v-model="form.capitalisation"
                                        outlined
                                        type="number"
                                        label="Capitalization"
                                        placeholder="enter company's worth"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'no of employees is required']"
                                        v-model="form.number_of_employees"
                                        type="number"
                                        outlined
                                        label="No Of Employees"
                                        placeholder="enter number of employees"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        v-model="form.other_business_info"
                                        outlined
                                        label="Other Business Info"
                                        placeholder="enter business info (optional)"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        :rules="[v=>!!v || 'address is required']"
                                        v-model="form.address"
                                        outlined
                                        label="Address"
                                        placeholder="enter address"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"></v-col>
                            <v-col cols="6">
                                <v-btn
                                        block
                                        color="primary"
                                        class="py-7"
                                        @click.prevent="proceedToTwo"
                                >
                                    Continue
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-stepper-content>

                    <!-- file information -->
                    <v-stepper-content step="2">
                        <v-card-text>
                            <div class="file-input text-center py-2 px-10" @click.prevent="$refs.cacCert.click()">
                                <div class="text-capitalize pt-2">{{'Upload CAC Certificate'}}</div>
                                <div style="border: 1px dashed grey" class="d-flex justify-center pa-2">
                                    <v-icon>mdi-file-account</v-icon>
                                    <div class="text-uppercase pl-5">choose file</div>
                                </div>
                            </div>

                            <div class="file-input text-center py-2 px-10" @click.prevent="$refs.cacDocs.click()">
                                <div class="text-capitalize pt-2">{{'Upload CAC Documents'}}</div>
                                <div style="border: 1px dashed grey" class="d-flex justify-center pa-2">
                                    <v-icon>mdi-file-account</v-icon>
                                    <div class="text-uppercase pl-5">choose file</div>
                                </div>
                            </div>

                            <div class="file-input text-center py-2 px-10" @click.prevent="$refs.tin.click()">
                                <div class="text-capitalize pt-2">{{'Upload Tin Certificate'}}</div>
                                <div style="border: 1px dashed grey" class="d-flex justify-center pa-2">
                                    <v-icon>mdi-file-account</v-icon>
                                    <div class="text-uppercase pl-5">choose file</div>
                                </div>
                            </div>

                            <div class="file-input text-center py-2 px-10" @click.prevent="$refs.memorandum.click()">
                                <div class="text-capitalize pt-2">{{'Upload Memorandum'}}</div>
                                <div style="border: 1px dashed grey" class="d-flex justify-center pa-2">
                                    <v-icon>mdi-file-account</v-icon>
                                    <div class="text-uppercase pl-5">choose file</div>
                                </div>
                            </div>

                            <div class="file-input text-center py-2 px-10"  @click.prevent="$refs.address.click()">
                                <div class="text-capitalize pt-2">{{'Upload proof of address'}}</div>
                                <div style="border: 1px dashed grey" class="d-flex justify-center pa-2">
                                    <v-icon>mdi-file-account</v-icon>
                                    <div class="text-uppercase pl-5">choose file</div>
                                </div>
                            </div>

                            <input type="file" @change.prevent="selectCacCert" accept="application/pdf,image/*" ref="cacCert" style="display: none;"/>
                            <input type="file" @change.prevent="selectCacDoc" accept="application/pdf,image/*" ref="cacDocs" style="display: none;"/>
                            <input type="file" @change.prevent="selectMemorandum" accept="application/pdf,image/*" ref="memorandum" style="display: none;"/>
                            <input type="file" @change.prevent="selectTin" accept="application/pdf,image/*" ref="tin" style="display: none;"/>
                            <input type="file" @change.prevent="selectAddress" accept="application/pdf,image/*" ref="address" style="display: none;"/>

                        </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div v-if="guarantors.length">
                            <v-row class="mb-3">
                                <v-col cols="6">
                                    <div class="text-h5 font-weight-bold">Loan Guarantors</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="float-right">
                                        <v-btn
                                                color="primary" class="py-6 px-7"
                                                @click.prevent="addModal=true"
                                        >
                                            Add Guarantor
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-data-table
                                    :headers="headers"
                                    :items="guarantors"
                                    disable-pagination
                                    :hide-default-footer="true"
                            >
                                <template v-slot:[`item.index`]="{ item }">
                                    {{ guarantors.indexOf(item) + 1 }}
                                </template>
                                <template v-slot:[`item.file_name`]="{ item }">
                                    <v-btn small color="info" @click="previewDocument(item.uploaded_file_url)">
                                        <v-icon class="white--text">mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.created_at`]="{ item }">
                                    <div>{{ item.created_at | dashboardDateFormat }}</div>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-avatar
                                            @click="removeGuarantor(item)"
                                            color="secondary"
                                            size="30"
                                    >
                                        <v-icon class="white--text">mdi-delete</v-icon>
                                    </v-avatar>
                                </template>
                            </v-data-table>

                            <v-card-actions class="mt-5">
                                <v-spacer/>
                                <v-btn
                                        color="secondary"
                                        @click.prevent="step = 2"
                                        class="py-sm-5 py-md-7 px-sm-7 px-md-13 mr-5"
                                >
                                    Back
                                </v-btn>
                                <v-btn
                                        color="primary"
                                        class="py-sm-5 py-md-7 px-sm-7 px-md-13"
                                        @click.prevent="submitBusinessAccount"
                                >
                                    <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-3" width="5"/>
                                    Submit
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <div v-else class="d-flex justify-center align-center" style="height: 400px;">
                            <v-col cols="6">
                                <div class="text-center">
                                    <div class="text-h5">Empty guarantor list!</div>
                                    <div class="my-5">Please add at least two guarantors to continue</div>
                                    <v-btn
                                            color="primary" class="py-6 px-7"
                                            @click.prevent="addModal=true"
                                    >
                                        Add Guarantor
                                    </v-btn>
                                </div>
                            </v-col>
                        </div>
                    </v-stepper-content>
                </v-form>
            </v-stepper-items>
        </v-stepper>
        <v-dialog
                v-model="addModal"
                @click:outside="addModal=false"
                persistent
                max-width="600px"
        >
            <v-card class="pa-3">
                <v-card-title class="headline white--text pa-0">
                    <v-app-bar
                            height="50"
                            color="transparent"
                            class="pa-1 mx-1 mt-0"
                            flat
                    >
                        <v-spacer/>
                        <v-app-bar-title class="text-uppercase modal-title">Add Business Guarantor</v-app-bar-title>
                        <v-spacer/>
                        <v-btn icon @click="addModal=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>
                <!-- add guarantor -->
                <v-form ref="form" lazy-validation>
                    <div class="pa-2">
                        <v-card-text>
                            <v-row class="mt-4">
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.name"
                                            outlined
                                            dense
                                            label="Name"
                                            :rules="[v =>!!v || 'guarantor name required']"
                                            placeholder="enter name"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.phone"
                                            outlined
                                            dense
                                            label="Phone"
                                            :rules="[v =>!!v || 'guarantor phone required', v=>v.length>9 || 'phone no. is is too short', v=>v.length < 20 || 'phone no. is too long']"
                                            placeholder="enter phone number"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.email"
                                            outlined
                                            dense
                                            label="Email (optional)"
                                            placeholder="enter email"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-select
                                            v-model="guarantor.id_card_type_id"
                                            :items="idcards"
                                            :rules="[v =>!!v || 'id card type required']"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="select id card type"
                                            label="ID Card Type"
                                            outlined
                                            dense
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.id_card_number"
                                            outlined
                                            dense
                                            :rules="[v =>!!v || 'id card number required']"
                                            label="ID Card Number"
                                            placeholder="enter ID card number"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-file-input
                                            v-model="guarantor.file"
                                            @change="onFileChange"
                                            label="Upload File"
                                            outlined
                                            dense
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.address"
                                            outlined
                                            dense
                                            label="Address"
                                            :rules="[v =>!!v || 'guarantor address required']"
                                            placeholder="enter address"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>

                            <v-btn
                                    color="primary"
                                    class="py-sm-5 py-md-6 px-sm-7 px-md-10 mr-2"
                                    @click.prevent="handleAddGuarantor"
                            >
                                Add
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <pre>{{guarantors}}</pre>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            }
        },
        data: () => ({
            step: 1,
            processing: false,
            menu1: false,
            addModal: false,
            form: new Form({
                business_id: '',
                country: '',
                state: '',
                lga: '',
                address: '',
                other_business_info: '',
                number_of_employees: '',
                capitalisation: '',
                is_onboarded: '',
                is_completed: '',
                tin_file: '',
                cac_cert_file: '',
                cac_docs_file: '',
                memorandum_file: '',
                address_file: '',
            }),
            guarantor: new Form({
                name: '',
                email: '',
                phone: '',
                address: '',
                id_card_type_id: '',
                id_card_number: '',
                file: ''
            }),
            headers: [
                {text: 'S/No', value: 'index', align: 'start', sortable: false},
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
                {text: 'card no.', value: 'id_card_number'},
                {text: 'Uploaded File', value: 'file_name'},
                {text: 'Date', value: 'created_at'},
                {text: 'Action', value: 'action'},
            ],

        }),
        methods: {
            ...mapActions('loan', ['allIdCardTypes']),
            ...mapActions('business', ['createGuarantor', 'allGuarantors', 'createBusinessAccount']),
            ...mapMutations('business', ['storeBusinessAccount', 'storeGuarantor', 'deleteGuarantor']),
            submitBusinessAccount() {
                this.processing = true;
                this.form.user_id = this.user.id;
                this.createBusinessAccount(this.form).then(res => {
                    this.$toast.success('business kyc updated successfully');
                    this.storeBusinessAccount(res.data);
                    this.$emit('onSubmit');
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.processing = false;
                });

            },
            previewDocument(url) {
                window.open(url, "_blank");
            },
            onFileChange(e) {
                this.guarantor.file = e;
            },
            handleAddGuarantor() {

                if(this.guarantor.name!==''&&this.guarantor.phone!==''&&this.guarantor.id_card_number!==''&& this.guarantor.file.name) {
                    const data = new FormData();
                    data.append('loan_kyc_id', this.loanAccount.id);
                    data.append('name', this.guarantor.name);
                    data.append('phone', this.guarantor.phone);
                    data.append('email', this.guarantor.email);
                    data.append('address', this.guarantor.address);
                    data.append('id_card_type_id', this.guarantor.id_card_type_id);
                    data.append('id_card_number', this.guarantor.id_card_number);
                    data.append('file', this.guarantor.file);
                    this.createGuarantor(data);
                    this.guarantor.reset();
                }else {
                    this.$toast.error('Validation errors');
                }
                this.addModal = false;
            },
            removeGuarantor(guarantor) {
                this.deleteGuarantor(guarantor.id);
            },
            proceedToTwo() {
                let validate = this.form.country !=='' && this.form.state !=='' && this.form.lga !=='' && this.form.address !=='' && this.form.other_business_info !=='' && this.form.number_of_employees !=='' && this.form.capitalisation !=='';
                if(validate) {
                    this.step = 2;
                }else {
                    this.$toast.error('Errors exist on current page');
                }
            },
            selectCacDoc(event) {
                this.form.cac_docs_file = event.target.files[0];
            },
            selectTin(event) {
                this.form.tin_file = event.target.files[0];
            },
            selectCacCert(event) {
                this.form.cac_cert_file = event.target.files[0];
            },
            selectAddress(event) {
                this.form.address_file = event.target.files[0];
            },
            selectMemorandum(event) {
                this.form.memorandum_file = event.target.files[0];
            },
        },
        computed: {
            ...mapGetters('business', ['businessAccount', 'guarantors']),
            ...mapGetters('loan', ['idcards']),
            ...mapGetters('auth', ['user']),
            empDate() {
                return this.form.employment_date ? moment(this.form.employment_date).format('Do MMM [,] YYYY') : ''
            }
        },
        mounted() {
            this.allGuarantors(this.$route.params.id);
            this.allIdCardTypes({});
            this.form.fill(this.businessAccount);
        },
    }
</script>

<style scoped lang="scss">
    .form-input {
        margin-top: -20px!important;
    }
    .row + .row {
        margin-top: 0 !important;
    }
    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
        width: 100%;
        padding-top: 12px;
        padding-right: 12px;
        padding-bottom: 0!important;
        padding-left: 12px;
    }
    .v-stepper__wrapper {
        /* overflow: hidden; */
        transition: none;
    }
    .logo-input {
        height: 100px;
        border: 1px grey dashed;
        margin-bottom: 25px;
    }
    .logo-input:hover,.file-input:hover {
        background-color: #bcc1c6;
        cursor: pointer;
    }
    .file-input {
        border: 1px solid #bcc1c6;
        height: 100px;
        margin-bottom: 20px;
    }
    .file-input:last-child {
        margin-bottom: 0;
    }
    .dashed-divider {
        border-top: 4px dashed #E0E0E0
    }
    .plain-divider {
        border-top: 4px solid #E0E0E0
    }
    .photo-input:hover {
        background-color: #bcc1c6;
        cursor: pointer;
    }
</style>
