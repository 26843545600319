<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="pa-lg-10 pa-sm-5">
            <v-app-bar
                    height="30"
                    color="transparent"
                    class="pa-0"
                    flat
            >
                <v-spacer/>
                <v-app-bar-title class="secondary--text modal-title">
                    Tran<span class="primary--text">Save</span>
                </v-app-bar-title>
                <v-spacer/>
                <v-btn icon @click="$emit('onDismiss')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>

            <v-form @submit.prevent="showLoanSummary" v-model="isValid" lazy-validation ref="form">
                <div class="my-md-5 my-sm-3">Loan Calculator</div>
                <v-text-field
                        v-model="form.amount"
                        :rules="rules.amount"
                        @keydown="form.clear()"
                        type="number"
                        outlined
                        class="mt-md-6 mt-sm-2"
                        label="Amount"
                        placeholder="enter amount here"
                />
                <div class="pb-0" style="margin-top: -20px;">
                    <v-subheader class="primary--text mb-4">
                        For How Long?
                    </v-subheader>
                    <v-slider
                            :max="120"
                            :min="10"
                            v-model="form.duration"
                            thumb-label="always"
                    ></v-slider>
                </div>
                <v-textarea
                        v-model="form.description"
                        auto-grow
                        outlined
                        :rules="rules.description"
                        label="Description"
                        placeholder="enter description"
                        rows="1"
                ></v-textarea>

                <v-btn
                        block
                        tile
                        color="primary darken-1"
                        class="py-7"
                        type="submit"
                >
                    <v-progress-circular v-if="processing" indeterminate width="5" color="secondary" class="mr-2"></v-progress-circular>
                    Calculate Loan
                </v-btn>
            </v-form>

        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        props: ['show'],
        data: () => ({
            processing: false,
            val: Number,
            isValid: true,
            rules: {
                duration: [
                    value => !!value || 'Duration required.',
                    value => (value && value > 10) || 'Min 10 days',
                    value => (value && value < 30) || 'Max 30 days',
                ],
                amount: [
                    val => !!val || 'Amount is required',
                    val => (val && val > 0) || 'Amount can not be zero',
                ],
            },
            form: new Form({
                amount: '',
                duration: '',
                owner_id: '',
                description: '',
            }),
        }),
        methods: {
            ...mapActions('loan', ['calculateLoan']),
            showLoanSummary() {
                if (this.$refs.form.validate()) {
                    const data = {
                        amount: this.form.amount,
                        owner_id: this.form.owner_id,
                        duration: this.form.duration,
                        description: this.form.description,
                        role: 'user',
                    };
                    this.processing = true;
                    this.calculateLoan(data).then(res => {
                        if (res.data.amount) {
                            res.data.owner_id = this.form.owner_id;
                            this.$emit('onGetLoanDetails', res.data);
                        }else {
                            this.$toast.error('You have unpaid outstanding loan');
                            this.$emit('onDismiss');
                        }
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    }).finally(() => {
                        this.processing = false;
                    });

                }else {
                    this.$toast.error('Validation error on page');
                }
            },
        },
        mounted() {
            //this.form.fill(this.requestData);
        }
    }
</script>

<style scoped>

</style>